import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields?: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerDefault = styled.section`
  position: relative;
  overflow: hidden !important;
  height: 100%;
  z-index: 1;
  height: auto;
  &:before {
    content: '';
    height: 100%;
    min-height: 434px;
    width: 130%;
    position: absolute;
    z-index: -1;
    left: -15%;
    top: 0;
    @media (min-width: 576px) {
      border-radius: 0 0 50% 50%;
    }
    background: ${({ theme }) => theme.color.primary};
  }
`

const BannerWrapper = styled.div`
  overflow: hidden !important;
  position: relative;
  height: auto;
  min-height: 434px;
  z-index: -1;
`

const BannerDefault: React.FC<BannerDefaultProps> = ({ fields, children }) => (
  <StyledBannerDefault className="mb-5">
    <BannerWrapper>
      <div className="container h-100 d-flex ">
        <div className="row justify-content-center align-items-end w-100">
          <div className="col-lg-9">
            {fields?.description ? (
              <div className="pt-5">
                <ParseContent
                  content={fields.description}
                  className="w-100 mb-5 py-5 mt-5"
                />
              </div>
            ) : (
              <div className="w-100">{children}</div>
            )}
          </div>
        </div>
      </div>
    </BannerWrapper>
  </StyledBannerDefault>
)

export default BannerDefault
