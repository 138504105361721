import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

interface BannerDetailProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerDetail = styled.section`
  position: relative;
  overflow: hidden !important;
  height: 100%;
`

const BannerUpper = styled.div`
  position: relative;
  z-index: 1;
`

const BannerWrapper = styled.div<{ hasExtraImage: boolean }>`
  min-height: 384px;
  z-index: 1;
  &:before {
    content: '';
    height: 100%;
    width: 130%;
    position: absolute;
    z-index: 2;
    left: -15%;
    top: 0;
    ${(props) =>
      props.hasExtraImage &&
      css`
        background: rgba(18, 21, 95, 0.5);
      `}
    ${(props) =>
      !props.hasExtraImage &&
      css`
        background: ${({ theme }) => theme.color.primary};
      `}
    @media (min-width: 576px) {
      border-radius: 0 0 50% 50%;
    }
  }
`

const ImageWrapper = styled.div`
  @media (min-width: 576px) {
    top: 0;
  }
  @media (max-width: 767px) {
    top: 10px;
    right: 20px;
  }
  @media (max-width: 575px) {
    top: -20px;
    right: 20px;
  }
`

const Image = styled(Plaatjie)<{ mirror: boolean }>`
  z-index: 2;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.mirror &&
    css`
      @media (min-width: 992px) {
        transform: scaleX(-1);
      }
    `}

  @media (min-width: 992px) {
    height: 589px;
    max-width: 451px;
  }
  @media (max-width: 991px) {
    height: 489px;
    max-width: 351px;
  }
  @media (max-width: 767px) {
    height: 439px;
    max-width: 301px;
  }
  @media (max-width: 575px) {
    height: 319px;
    max-width: 181px;
  }
  @media (min-width: 1200px) {
    margin-top: -10rem;
  }
  @media (max-width: 1199px) {
    margin-top: -9rem;
  }
  @media (max-width: 575px) {
    margin-top: -8rem;
  }
`

const ExtraImage = styled(Plaatjie)`
  height: 384px;
  width: 130%;
  left: -15%;
  @media (min-width: 576px) {
    border-radius: 0 0 50% 50%;
  }
`

const Content = styled(ParseContent)`
  position: relative;
  padding-top: 6rem;
  z-index: 4 !important;
  & h1,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: ${({ theme }) => theme.font.size.giga};
    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.huge} !important;
    }
  }
  & h2 {
    font-size: ${({ theme }) => theme.font.size.huge};
    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.huge} !important;
    }
  }
  & p {
    padding-top: 1rem;
    font-size: ${({ theme }) => theme.font.size.huge};
    & a {
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }
`

const Fade = styled.div`
  position: absolute;
  width: 100%;
  z-index: 8;
  height: 40%;
  bottom: 0;
  left: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 0) 100%
  );
`

const BannerLower = styled.div`
  z-index: 3;
`

const BannerDetail: React.FC<BannerDetailProps> = ({ fields }) => (
  <StyledBannerDetail className="mb-5 pb-5">
    <BannerUpper className="position-relative">
      <BannerWrapper
        className="position-relative overflow-hidden"
        hasExtraImage={fields.extraimage !== null || false}
      >
        {fields.extraimage && (
          <ExtraImage
            image={fields.extraimage}
            className="position-absolute overflow-hidden"
            alt=""
          />
        )}

        <div className="container h-100 d-flex align-items-end align-items-sm-center justify-content-end py-5">
          <div className="row w-100 h-100 justify-content-end align-items-center">
            <div className="col-lg-7">
              <Content content={fields.description} className="px-lg-5" />
            </div>
          </div>
        </div>
      </BannerWrapper>
    </BannerUpper>
    <BannerLower className="container position-relative">
      <div className="row">
        <div className="col-lg-5 d-flex justify-content-end">
          <ImageWrapper className="position-relative">
            <Image
              image={fields.image}
              alt="alt"
              mirror={fields.mirrorimage || false}
            />
            <Fade />
          </ImageWrapper>
        </div>
        <div className="col-lg-7 pt-5">
          <div className="px-xl-4">
            <ParseContent content={fields.description2} className="px-xl-5" />
          </div>
        </div>
      </div>
    </BannerLower>
  </StyledBannerDetail>
)

export default BannerDetail
