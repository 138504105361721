import React from 'react'
import styled from 'styled-components'

// Elements
import Plaatjie from '@ubo/plaatjie'

interface BannerImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerImage = styled.section`
  position: relative;
  overflow: hidden !important;
  @media (min-width: 576px) {
    border-radius: 0 0 50% 50%;
    height: 63vh;
    height: 100%;
    width: 130%;
    position: absolute;
    z-index: -1;
    left: -15%;
    top: 0;
  }
  @media (max-width: 575px) {
    height: 400px;
  }
`

const BannerWrapper = styled.div`
  overflow: hidden !important;
  position: relative;
  height: 63vh;
  z-index: -1;
  background: rgb(18, 21, 95);
  background: linear-gradient(
    180deg,
    rgba(18, 21, 95, 1) 0%,
    rgba(18, 23, 95, 0) 94%
  );
`

const StyledPlaatjie = styled(Plaatjie)`
  position: absolute !important;
  height: 100%;
  right: 0;
  top: 0;
  z-index: -2 !important;
`

const BannerImage: React.FC<BannerImageProps> = ({ fields }) => (
  <StyledBannerImage className="position-relative">
    <BannerWrapper />
    <StyledPlaatjie alt="banner" className="w-100" image={fields?.image} />
  </StyledBannerImage>
)

export default BannerImage
