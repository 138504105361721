import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

interface BannerLandingProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerLanding = styled.section`
  position: relative;
  z-index: 1;
  overflow: hidden !important;

  @media (min-width: 576px) {
    border-radius: 0 0 50% 50%;
    min-height: 675px;
    width: 130%;
    position: absolute;
    z-index: -1;
    left: -15%;
    top: 0;
  }

  &:before {
    content: '';
    height: 100%;
    width: 130%;
    position: absolute;
    z-index: 1;
    left: -15%;
    top: 0;
    overflow: hidden;
    @media (min-width: 576px) {
      border-radius: 0 0 50% 50%;
      background: linear-gradient(
        90deg,
        rgba(18, 21, 95, 1) 42%,
        rgba(18, 21, 95, 0) 90%
      );
    }
    @media (max-width: 575px) {
      background: rgb(18, 21, 95);
      background: linear-gradient(
        360deg,
        rgba(18, 21, 95, 1) 0%,
        rgba(18, 21, 95, 0.40800070028011204) 100%
      );
    }
  }
`

const BannerWrapper = styled.div`
  position: relative;
  z-index: 1;
  @media (min-width: 576px) {
    min-height: 675px;
  }
  @media (max-width: 575px) {
    min-height: 50vh;
  }
`

const Image = styled(Plaatjie)<{ mirror: boolean }>`
  position: absolute !important;
  height: 100%;
  right: 0;
  top: 0;
  z-index: -1 !important;
  ${(props) =>
    props.mirror &&
    css`
      transform: scaleX(-1);
    `}

  @media (min-width: 576px) {
    width: 60%;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
`

const Content = styled(ParseContent)`
  padding-top: 8rem;
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: ${({ theme }) => theme.font.size.giga};
  }

  & p {
    font-size: ${({ theme }) => theme.font.size.huge};
    line-height: 1.65;
    & span {
      @media (max-width: 575px) {
        margin-top: 1rem;
      }
    }
    & a {
      font-size: ${({ theme }) => theme.font.size.big};
      line-height: 1.25;
    }
  }

  & .about-us {
    font-size: ${({ theme }) => theme.font.size.medium};
    line-height: 1.9 !important;
  }
`

const BannerLanding: React.FC<BannerLandingProps> = ({ fields }) => (
  <StyledBannerLanding className="position-relative">
    <BannerWrapper>
      <div className="container h-100 d-flex align-items-end align-items-sm-center py-5">
        <Content
          content={fields.description}
          className="px-lg-5 mb-lg-5 mb-sm-0"
        />
      </div>
    </BannerWrapper>
    <Image
      alt="banner"
      image={fields?.image}
      mirror={fields.mirrorimage || false}
    />
  </StyledBannerLanding>
)

export default BannerLanding
